/*
    components: card.scss;
*/

.card {
  &--primary {
    background-color: $white;
    padding: 12px;
    border: 1px solid $grey-ed;
    border-radius: $border-rounded;

    &--shadow {
      box-shadow: 2px 2px 12px $grey-ed;
    }
  }

  &--secondary {
    height: calc(100% - 48px);
    overflow-y: auto;
    background-color: $white;
    border-radius: $border-rounded;
    margin: calc(#{$unit} * 6);
    border: 1px solid $grey-ed;

    &__head {
      z-index: 7;
      position: sticky;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: calc(#{$unit} * 6);
      border-bottom: 1px solid $grey-ed;

      backdrop-filter: blur(20px);
      background-color: rgba(255, 255, 255, 0.8);

      &--search-tutor {
        @media (max-width: $responsive-xl) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    &__body {
      padding: calc(#{$unit} * 10) calc(#{$unit} * 6);
    }

    &--alt {
      margin: 0;
      height: 100%;
    }
  }

  &--mini-calendar {
    background-color: $white;
    padding: calc(#{$unit} * 4);
    border: 1px solid $grey-ed;
    border-radius: $border-rounded;
    margin-bottom: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }

  &--calendar {
    background-color: $white;
    border: 1px solid $grey-ed;
    border-radius: $border-rounded;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &--lessons {
    height: calc(100% - 48px);
    overflow-y: auto;
    background-color: $white;
    border-radius: $border-rounded;
    margin: calc(#{$unit} * 6);
    border: 1px solid $grey-f5;

    &__head {
      z-index: 9;
      position: sticky;
      top: 0;
      height: 85px;

      padding: calc(#{$unit} * 6);
      border-bottom: 1px solid $grey-ed;

      font-size: 24px;
      font-weight: 600;
      backdrop-filter: blur(20px);
      background-color: rgba(255, 255, 255, 0.8);
    }

    &__body {
      display: flex;
      height: calc(100% - 85px);

      &__aside {
        width: 360px;
        flex-shrink: 0;
        border-right: 1px solid $grey-ed;
        overflow-y: auto;
      }

      &__main {
        flex-grow: 1;
        padding: calc(#{$unit} * 10) calc(#{$unit} * 14);
      }
    }
  }

  &--chat {
    height: calc(100% - 48px);
    width: calc(100% - 48px);
    background-color: $white;
    border-radius: $border-rounded;
    margin: calc(#{$unit} * 6);
    display: flex;

    &__aside {
      flex-shrink: 0;
      width: 330px;
      display: flex;
      flex-direction: column;
      border-right: 1px solid $grey-ed;
    }

    &__content {
      flex-grow: 1;
      padding: calc($unit * 6);
      display: flex;
      flex-direction: column;
      border-left: 1px solid $grey-ed;
    }
  }

  &--profile {
    height: calc(100% - 48px);
    // max-width: 1280px;

    margin: calc(#{$unit} * 6);
    padding: calc(#{$unit} * 6);

    border-radius: $border-rounded;
    border: 1px solid $grey-f5;

    overflow-y: auto;
    overflow-x: hidden;

    background-color: $white;

    &__section {
      display: flex;
      border-bottom: 1px solid $grey-ed;
      padding-bottom: calc(#{$unit} * 10);
      margin-bottom: calc(#{$unit} * 10);

      @media (max-width: $responsive-lg) {
        flex-direction: column;
      }

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      //select first childs in section card and set their styles
      & > div {
        &:first-child {
          width: 320px;
          flex-shrink: 0;
          padding-right: calc(#{$unit} * 6);

          @media (max-width: $responsive-lg) {
            margin-bottom: calc(#{$unit} * 6);
            padding-right: 0;
          }
        }

        &:last-child {
          flex-grow: 1;
        }
      }
    }

    &__progress {
      box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.05);

      @media (max-width: $responsive-xl) {
        flex-direction: column;
      }

      &__links {
        display: flex;
        flex-grow: 1;
        justify-content: space-evenly;
        align-items: center;

        width: 100%;

        overflow-x: auto;
      }

      //select first childs in section card and set their styles
      & > div {
        &:first-child {
          flex-shrink: 0;
          @media (max-width: $responsive-xl) {
            margin-bottom: calc(#{$unit} * 10);
          }
        }

        &:last-child {
          flex-grow: 1;
        }
      }
    }

    &__progress {
      box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.05);

      @media (max-width: $responsive-xl) {
        flex-direction: column;
      }

      //select first childs in section card and set their styles
      & > div {
        &:first-child {
          flex-shrink: 0;
          @media (max-width: $responsive-xl) {
            margin-bottom: calc(#{$unit} * 10);
          }
        }

        &:last-child {
          flex-grow: 1;
        }
      }
    }

    &__savebtn {
      opacity: 1;

      &:disabled {
        opacity: 0;
        pointer-events: none;
        user-select: none;
      }
    }
  }

  &--earnings {
    overflow: hidden;
    position: relative;
    background-color: $white;
    padding: calc(#{$unit} * 6);
    border: 1px solid $grey-ed;
    border-radius: $border-rounded;

    @media (max-width: $responsive-xl) {
      margin-bottom: calc(#{$unit} * 6);
    }

    &__title {
      font-size: 24px;
      color: $color-primary;
      font-weight: 600;
      margin-bottom: calc(#{$unit} * 1);
    }

    i {
      width: 61px;
      height: 61px;
      position: absolute;
      bottom: 24px;
      right: 24px;
      background-color: $color-primary-lighter;
    }
  }

  &--dashboard {
    background-color: $white;
    padding: 24px;
    border: 1px solid $grey-ed;
    border-radius: $border-rounded;

    &--brand {
      background-color: $color-primary;
      color: $white;

      .card--dashboard__btn {
        color: white;
        background-color: rgba(255, 255, 255, 0.15);
      }
    }

    &__text {
      opacity: 0.7;
      margin-bottom: calc(#{$unit} * 4);
      min-height: 60px;

      &--ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        -webkit-box-sizing: border-box;

        max-height: 60px;
      }
    }

    &__btn {
      background-color: rgba(124, 106, 244, 0.05);
      color: $color-primary;

      i {
        pointer-events: none;
      }
    }
  }
}

.tutor-card {
  transition: transform 0.3s;
  border-radius: 10px;
  box-shadow: 0px 2px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 10px -3px rgba(0, 0, 0, 0.1), 0px 2px 10px -3px rgba(0, 0, 0, 0.1);
}

@keyframes cardAppear {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.card-appear-animation {
  animation: cardAppear 1.5s ease forwards;
}