/*
    components: tutor-list.scss
*/

$tutor-list-img-width: 176px;
$tutor-list-details-width: 264px;

.tutor-list {
  &__item {
    display: flex;
    padding: calc(#{$unit} * 6);
    border: 1px solid $grey-ed;
    border-radius: $border-rounded;
    margin-bottom: calc(#{$unit} * 4);
    padding-right: calc(#{$unit} * 4);

    &:last-child {
      margin-bottom: 0;
    }

    &__img {
      width: $tutor-list-img-width;
      flex-shrink: 0;

      img {
        width: 154px;
        height: 154px;
        object-fit: cover;
        border-radius: $border-circle;
        border: 11px solid $color-primary-lighter;
      }
    }

    &__info {
      flex-grow: 1;
    }

    &__details {
      display: flex;
      flex-direction: column;
      width: $tutor-list-details-width;
      flex-shrink: 0;
      border-left: 1px solid $grey-ed;
      padding-left: calc(#{$unit} * 6);
    }
  }

  &__no-results {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      margin-bottom: calc($unit * 4);
      font-size: 40px;
    }

    &__subtitle {
      margin-bottom: calc($unit * 10);
      color: $color-text-primary;
    }
  }
}
