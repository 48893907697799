/*
    utils: cursor.scss
*/

.cur {
  &--pointer {
    cursor: pointer;
  }

  &--default {
    cursor: default;
  }
}
