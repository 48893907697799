/*
    utils: position
*/

.pos {
  &--rel {
    position: relative;
  }

  &--abs {
    position: absolute;
  }
}
