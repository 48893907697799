/*
    base: base-styles.scss
*/

* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden !important;
}

body {
  //fluid typography
  //@include fluid-type($fluid-min-width, $fluid-max-width, $type-base - $fluid-type-modifier, $type-base);

  //fixed typography
  font-size: $type-base;

  font-family: 'Lato', sans-serif;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.star {
  &--1 {
    path:nth-child(1) {
      fill: $color-primary;
    }
  }

  &--2 {
    path:nth-child(-n + 2) {
      fill: $color-primary;
    }
  }

  &--3 {
    path:nth-child(-n + 3) {
      fill: $color-primary;
    }
  }

  &--4 {
    path:nth-child(-n + 4) {
      fill: $color-primary;
    }
  }

  &--5 {
    path:nth-child(-n + 5) {
      fill: $color-primary;
    }
  }
}
