/*
    components: layout
*/

$sidebar-width: 256px;
$mobile-height: 50px;

.layout {
  display: flex;
  @media (max-width: $responsive-md) {
    flex-direction: column;
  }

  &__aside {
    z-index: 8;
    position: relative;
    width: $sidebar-width;
    height: 100vh;
    flex-shrink: 0;
    color: $white;

    &__close {
      display: none;
    }

    @media (max-width: $responsive-md) {
      position: fixed;
      top: 0;
      bottom: 0;
      left: -100vw;
      width: 100vw;
      transition: left 300ms ease-in-out;

      &.active {
        left: 0;
      }

      &__close {
        display: block;
      }
    }
  }

  &__mobile {
    display: none;
    align-items: center;
    justify-content: space-between;

    height: $mobile-height;
    padding: calc(#{$unit} * 4);
    border-bottom: 1px solid $border-color;

    background-color: $color-primary;
    color: $white;

    @media (max-width: $responsive-md) {
      display: flex;
    }
  }

  &__main {
    position: relative;
    flex-grow: 1;
    height: 100vh;
    overflow-y: auto;
    background-color: $background-color;

    @media (max-width: $responsive-md) {
      height: calc(100vh - #{$mobile-height});
    }
  }

  &__header {
    &--landing {
      //max-width: 1100px;
      padding: calc($unit * 4);
      margin: 0 auto;

      background-color: rgba(255, 255, 255, 1);
      transition: all 250ms ease-in-out;
    }

    &--background {
      background-color: $white;
      z-index: 30;
      position: sticky;
      top: 0;
      box-shadow: 0 0 15px 0px rgb(0, 0, 0, 0.3);
      transition: box-shadow 400ms ease-in-out;

      &.active {
        box-shadow: 0 0 0 0 rgb(0, 0, 0, 0);
      }

      &--primary {
        background: linear-gradient(25.68deg, rgba(127, 95, 211, 0.9) 0.44%, #5c3ee8 100%);

        .layout__header--landing {
          background-color: transparent;

          .nav-link--landing {
            color: $white;

            &::after {
              background-color: $white;
            }
          }

          .btn--ghost--landing {
            border-color: $white;
            color: $white;
          }

          .btn--primary--landing {
            background: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
  }

  &--primary {
    padding: calc(#{$unit} * 6);
    display: flex;
    height: 100%;

    @media (max-width: $responsive-lg) {
      flex-direction: column;
    }

    & > div {
      &:first-child {
        flex-grow: 1;
        width: 100%;
      }

      &:last-child {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        width: 300px;
        margin-left: calc(#{$unit} * 6);

        @media (max-width: $responsive-lg) {
          margin-left: calc(#{$unit} * 0);
          margin-top: calc(#{$unit} * 4);
          padding-bottom: calc(#{$unit} * 4);
          width: 100%;
        }
      }
    }
  }
}
