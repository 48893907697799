/*
    base: typography.scss
*/

// Importing fonts
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Lato:wght@300;400;700;900&display=swap');

@import url("https://use.typekit.net/guo2uce.css");

// Global style
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  text-decoration: none;
  color: $color-primary;
}

p {
  margin: 0;
}
