/*
    components: content
*/

$header-height: 48px;
$footer-height: 72px;

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__header {
    height: $header-height;
    border-bottom: 1px solid $border-color;
    background-color: $white;
    flex-shrink: 0;

    &--chat {
      height: unset;
      padding: calc($unit * 4);

      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__main {
    display: flex;
    flex-grow: 1;
    flex-flow: column;
    flex-wrap: nowrap;

    padding: calc(#{$unit} * 6);
    overflow-y: auto;
    //scroll-behavior: smooth;

    //container sizes
    &--md {
      max-width: 1100px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $footer-height;
    min-height: $footer-height;
    padding: 0 calc(#{$unit} * 6);
    border-top: 1px solid $border-color;
    background-color: $white;

    &--chat {
      flex-wrap: wrap;
    }
  }
}

.carousel-img{
  transition: transform 1s;
  cursor: pointer;
}
.carousel-img:hover{
  transform: scale(1.5);
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  display: flex;
  flex-direction: row;
  width: 80%;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  position: relative;
}

.logos:before, .logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 1;
  @media (max-width: $responsive-md) {
    width: 100px;
  }
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), hsla(248, 84%, 69%, 1));
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), hsla(248, 84%, 69%, 1));
}


.logos-slide {
  display: flex;
  flex-direction: row;
  animation: 40s slide infinite linear;
}

.logos-slide img {
  height: 50px;
  margin: 0 40px;
  @media (max-width: $responsive-md) {
    margin: 0 20px;
  }
  transition: transform 1s;
}

.logos-slide img:hover{
    transform: translateZ(10px);
}

.carousel-card{
  padding: calc($unit * 2) calc($unit * 3);
  margin: 0 10px;
  background: $white;
  box-shadow: 8px 6px 12px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  transition: transform .2s; /* Animation */

  @media (max-width: $responsive-md) {
    padding: calc($unit * 1) calc($unit * 1);
  }
}
.carousel-card:hover{
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.carousel-container{
    display: flex;
    flex-direction: row;

  @media (max-width: $responsive-md) {
    flex-direction: column;
  }

    align-items: center;
    justify-content: center;
    max-width: 1100px;
    margin: 84px auto 0;
}

.carousel-title{
  width: 50%;
  font-size: $type-lg;;

    @media (max-width: $responsive-md) {
      width: 100%;
      margin-bottom: 24px;
      font-size: 18px;
    }
}