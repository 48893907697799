/*
    base: vars.scss
*/

// GLOBAL VARIABLES
//---------------------------------------------------------
$unit: 4px;
$scale: 0, 1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 18, 20, 22, 30;

// COLORS
//---------------------------------------------------------
/*
    webtool for checking contrast:
    https://webaim.org/resources/contrastchecker/
*/
//grey colors
$grey-30: #303030;
$grey-63: #636363;
$grey-7f: #7f7f7f;
$grey-bb: #bbbbbb;
$grey-b9: #b9b9b9;
$grey-c4: #c4c4c4;
$grey-d8: #d8d8d8;
$grey-ed: #ededed;
$grey-f2: #f5f5f5;
$grey-f5: #f5f5f5;
$grey-f7: #f7f7f7;
$grey-fb: #fbfbfb;
$grey-fc: #fcfcfc;

//background colors
$black: #000;
$white: #fff;
$background-color: #f8f7fa;
$background-overlay: #061129b3;
$background-overlay-grey: #2b2b2bb3;
$sidebar-overlay: #8f8f8f;

//main colors
$color-primary: #7e6cf2;
$color-primary-dark: #553dee;
$color-primary-light: #cbc3fb;
$color-primary-lighter: #f2f1fe;
$color-primary-bright: #f8f4fe;
$color-secondary: #0b8a00;

$color-success: #2ecc71;
$color-error: #e74c3c;
$color-warning: #e67e22;

// STATUS COLORS
//---------------------------------------------------------
$color-status-blue: #1b83fb;
$color-status-green: #0b8a00;

// BORDERS
//---------------------------------------------------------
$border-color: $grey-c4;
$border-rounded: 2 * $unit;
$border-circle: 1000px;

// TYPOGRAPHY
//---------------------------------------------------------
$color-text-primary: $grey-30;
$color-text-placeholder: $grey-d8;
$color-text-secondary: $grey-63;
$color-text-tertiary: $grey-bb;

$type-ty: 9px;
$type-xs: 10px;
$type-sm: 12px;
$type-base: 14px;
$type-md: 18px;
$type-lg: 24px;
$type-xl: 32px;
$type-xxl: 40px;
$type-huge: 64px;

// RESPONSIVE
//---------------------------------------------------------
//responsive variables
$responsive-sm: 576px;
$responsive-md: 768px;
$responsive-lg: 992px;
$responsive-xl: 1200px;

//spacers building configs
$modifiers: (
    '': '',
    't': '-top',
    'r': '-right',
    'b': '-bottom',
    'l': '-left',
);

$responsiveConfig: (
    $responsive-sm: 'sm',
    $responsive-md: 'md',
    $responsive-lg: 'lg',
    $responsive-xl: 'xl',
);

// FLUID TYPOGRAPHY
//---------------------------------------------------------
$fluid-type-modifier: 2px;
//set boundaries within fluid will work
$fluid-min-width: $responsive-sm;
$fluid-max-width: $responsive-xl;
//set min & max font sizes
$fluid-min-font: $type-base - $fluid-type-modifier;
$fluid-max-font: $type-base;
