/*
    utils: flex
*/

.flex {
  display: flex;

  &--inline {
    display: inline-flex;
  }

  &--primary {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--col {
    flex-direction: column;
  }

  &--row {
    flex-direction: row;
  }

  &--center {
    align-items: center;
  }

  &--end {
    align-items: flex-end;
  }

  &--start {
    align-items: flex-start;
  }

  &--jc {
    &--space-between {
      justify-content: space-between;
    }

    &--center {
      justify-content: center;
    }

    &--end {
      justify-content: flex-end;
    }

    &--start {
      justify-content: flex-start;
    }

    &--space-around {
      justify-content: space-around;
    }

    &--space-evenly {
      justify-content: space-evenly;
    }
  }

  &--gap-10{
    gap: 10px;
  }


  &--grow {
    flex-grow: 1;
  }

  &--shrink {
    flex-shrink: 0;
  }

  &--wrap {
    flex-wrap: wrap;
  }
}
