/*
    components: button.scss
*/

.btn {
  --box-shadow-color: #{$black};
  position: relative;
  display: inline-block;
  appearance: none;
  border: none;
  border-radius: $border-rounded;
  cursor: pointer;
  transition: all 200ms ease-out;

  &:focus {
    box-shadow: 0 0 2px 2px var(--box-shadow-color);
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    user-select: none;
  }

  //button styles
  &--primary {
    background-color: $color-primary;
    color: $white;
    transition: none !important;

    &:hover {
      background: linear-gradient(226.69deg, rgba(127, 95, 211, 0.9) 4.52%, #5c3ee8 94.64%);
    }

    &:active {
      background: #{darken($color-primary, 40%)};
    }

    &:focus {
      --box-shadow-color: #{darken($color-primary, 15%)};
    }

    &:disabled {
      background: $grey-d8;
      color: $grey-30;
    }

    &--landing {
      padding: calc((#{$unit} * 2) + 2px) calc(#{$unit} * 4.5);
      font-size: 16px;
      border-radius: 8px;
    }

  }

  &--secondary {
    background-color: $grey-d8;
    background: $white;
    color: $color-primary-dark;
    outline: 1px solid $color-primary-dark;

    &:hover {
      background: $grey-ed;
    }

    &:active {
      background-color: darken($grey-d8, 15%);
    }

    &:focus {
      --box-shadow-color: #{darken($grey-d8, 15%)};
    }
  }

  &--tertiary {

    @media (max-width: $responsive-sm) {
      width: calc(#{$unit} * 50);
    }

    background-color: transparent;
    color: $color-primary-dark;

    &:hover {
      color: darken($color-primary-dark, 20%)
    }

    &:active {
      background-color: darken($grey-d8, 15%);
    }

    &:focus {
      --box-shadow-color: #{darken($grey-d8, 15%)};
    }
  }

  &--success {
    background-color: $color-success;
    color: $white;

    &:hover {
      background-color: darken($color-success, 10%);
    }

    &:active {
      background-color: darken($color-success, 15%);
    }

    &:focus {
      --box-shadow-color: #{darken($color-success, 15%)};
    }
  }

  &--error {
    background-color: $color-error;
    color: $white;

    &:hover {
      background-color: darken($color-error, 10%);
    }

    &:active {
      background-color: darken($color-error, 15%);
    }

    &:focus {
      --box-shadow-color: #{darken($color-error, 15%)};
    }
  }

  &--ghost {
    background-color: transparent;
    color: $color-primary;
    border: 1px solid $color-primary;

    &--grey {
      border: 1px solid $grey-d8;

      &:hover {
        border: 1px solid $color-primary;
      }
    }

    &--error {
      border-color: $color-error;
      color: $color-error;
    }

    &--success {
      border-color: $color-success;
      color: $color-success;
    }

    &--bordered {
      border: 1px solid $color-primary;
      border-radius: $border-rounded;
    }

    &--landing {
      padding: calc(#{$unit} * 2) calc(#{$unit} * 4.5);
      font-size: 17px;
      border-radius: 8px;
      border-width: 2px;
      color: $color-primary;
    }

    &:focus {
      --box-shadow-color: #{darken($color-primary, 15%)};
    }
  }

  &--disabled {
    background-color: $color-primary;
    background: linear-gradient(226.69deg, rgb(61 60 64) 4.52%, rgb(140 139 143) 94.64%);
    color: $white;
    /*pointer-events: none;*/
    opacity: 0.7;
  }

  &--clear {
    background-color: transparent;
    color: $color-primary;
  }

  //button sizes
  &--smlr {
    font-size: 7px;
    padding: calc(#{$unit} * 1) calc(#{$unit} * 4);
  }

  &--sm {
    font-size: 14px;
    padding: calc(#{$unit} * 1) calc(#{$unit} * 4);
  }

  &--base {
    font-size: 14px;
    padding: calc(#{$unit} * 3) calc(#{$unit} * 4.5);
  }

  &--md {
    font-size: 16px;
    padding: calc(#{$unit} * 5) calc(#{$unit} * 5);
  }

  &--l {
    font-size: 17px;
    font-weight: bold;
    padding: calc(#{$unit} * 3) calc(#{$unit} * 20);
  }

  &--lg {
    font-size: 14px;
    padding: calc(#{$unit} * 3) calc(#{$unit} * 20);
  }

  &--lgr {
    width: max-content;
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    padding: calc(#{$unit} * 3) calc(#{$unit} * 15);
  }
}

.font-bold {
  font-weight: bold;
}
