/*
    components: field
*/

.field {
  position: relative;
  margin-bottom: calc(#{$unit} * 2);

  &__label {
    display: block;
    margin-bottom: calc(#{$unit} * 2);

    font-size: 14px;
    color: $color-text-primary;
  }

  &__validation {
    color: $color-error;
    margin-top: calc(#{$unit} * 1);
    min-height: 15px;
    font-size: $type-xs;
  }

  &__file {
    width: 100%;
    position: relative;

    &__wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100px;
      border: 1px solid $grey-c4;
      border-radius: 4px;
    }

    input[type='file'] {
      border-radius: 4px;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 20px;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
}
