/*
    pages: tax.scss;
*/

.tax {
  p {
    margin-bottom: calc(#{$unit} * 2);
    text-align: justify;
  }

  h1 {
    margin-bottom: calc(#{$unit} * 4);
    text-align: center;
  }

  h2 {
    margin-bottom: calc(#{$unit} * 2);
    margin-top: calc(#{$unit} * 10);
    text-align: center;
  }

  table {

    width: 100%;

    td {
      text-align: center;
      font-size: 18px;
    }

    tr {
      height: 40px;
      font-size: 20px;
    }

    tr:nth-child(even) {
      background-color: $color-primary-lighter;
    }

    th {
      color: white;
      background-color: $color-primary;
    }
  }

  ul.centered-list {
    display: grid;
    justify-items: left; // Horizontally center the items
    list-style: none; // Remove default list styles
    padding: 0; // Remove default padding
    margin: 0; // Remove default margin

    li {
      font-size: 32px;
      list-style-image: url('./../../assets/icons/green_circle_check.svg');
    }

  }
}
