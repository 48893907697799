/*
    components: dash-wrapper
*/

.dash-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  border: 1px dashed $color-primary-light;
  border-radius: 4px;
  max-width: 900px;
  max-height: 270px;
  overflow-y: auto;
  padding: calc($unit * 4);

  &--adaptive {
    max-height: unset;
  }

  &__item {
    width: 50%;
    padding: calc($unit * 4);

    &:nth-child(odd) {
      padding-right: 0;
      @media (max-width: 300px) {
        padding-right: 12px;
      }
    }

    &:nth-child(n + 3) {
      padding-top: 0;
    }

    @media (max-width: 300px) {
      width: 100%;
      &:nth-child(n + 2) {
        padding-top: 0;
      }
    }

    &__element {
      padding: calc($unit * 4);
      border: 1px solid $grey-ed;
      border-radius: $border-rounded;

      .subject {
        justify-content: unset;

        .price {
          margin: 0 25px 0 auto;
        }
      }

      &.active {
        background-color: $color-primary;
        color: white;

        i {
          background-color: white;
        }
      }
    }
  }
}
