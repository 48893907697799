/*
    components: image.scss
*/

.image {
  width: 48px;
  height: 48px;
  background-image: url(/assets/images/gradient-circle.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 1000px;

  &--big {
    width: 154px;
    height: 154px;
    border: 11px solid $color-primary-lighter;
    border-radius: 1000px;

    .image__initial {
      font-size: 48px;
    }
  }

  &__initial {
    font-weight: 600;
    font-size: 24px;
    color: $white;
  }

  &__profile {
    flex-shrink: 0;
    display: block;
    border-radius: $border-circle;
    border: 3px solid rgb(124, 106, 244, 10%);
    object-fit: cover;

    &--sm {
      width: 52px;
      height: 52px;
    }

    &--md {
      width: 80px;
      height: 80px;
      border-width: 5px;
    }
  }
}

.image-icon {
  width: 120px;
  height: 120px;

  @media (max-width: $responsive-lg) {
    margin-bottom: 20px;
    width: 100px;
    height: 100px;

  }
  margin-bottom: 40px;
}