/*
    components: table;
*/

.table {
  border-collapse: collapse;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;

  th {
    text-align: left;
  }

  &--availability {
    max-width: 720px;
    border: 1px solid $grey-ed;

    td {
      position: relative;
      padding: calc(#{$unit} * 4);
      border: 1px solid $grey-ed;

      font-weight: bold;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
    }

    &--check {
      background-color: $color-primary-bright;
    }

    &--close {
      background-color: $grey-fb;
    }
  }

  &--primary {
    border: 1px solid $grey-ed;

    td,
    th {
      border: 1px solid $grey-ed;
    }

    th {
      padding: calc(#{$unit} * 6);
    }

    td {
      padding: calc(#{$unit} * 4) calc(#{$unit} * 6);
    }

    &--zebra {
      tbody tr {
        &:nth-child(odd) {
          background-color: $color-primary-lighter;
        }
      }
    }
  }

  &--secondary {
    border: 1px solid $grey-ed;

    td,
    th {
      border: 1px solid $grey-ed;
    }

    th {
      padding: calc(#{$unit} * 6);
      background-color: $color-primary-bright;
    }

    td {
      padding: calc(#{$unit} * 4) calc(#{$unit} * 6);
    }

    tr {
      &:nth-child(even) {
        background-color: $grey-fb;
      }
    }
  }
}
