/*
    pages: legal-center.scss;
*/

.legal-center {
  p {
    margin-bottom: calc(#{$unit} * 2);
    text-align: justify;
  }

  h1 {
    margin-bottom: calc(#{$unit} * 4);
    text-align: center;
  }

  h2 {
    margin-bottom: calc(#{$unit} * 2);
    margin-top: calc(#{$unit} * 10);
    text-align: center;
  }

  li {
    color: #5c3ee8;

    &:hover {
      color: black;
    }
  }
}
