/*
    components: availability.scss;
*/

.availability {
  &__checkbox {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: $white;
    border: 1px solid $color-primary-lighter;
    border-radius: $border-rounded;
    margin-right: calc(#{$unit} * 2);

    &.checked {
      background-color: $color-primary;

      &:after {
        content: '';
        position: absolute;
        top: 4px;
        left: 3px;
        width: 8px;
        height: 4px;
        border-left: 2px solid $white;
        border-bottom: 2px solid $white;
        transform: rotate(-45deg);
      }
    }
  }

  &__field {
    display: inline-flex;
    align-items: center;
    margin-right: calc(#{$unit} * 6);
    cursor: pointer;
  }

  &__label {
    text-transform: uppercase;
    user-select: none;
  }
}
