/*
    components: events.scss
*/

.event {
  position: absolute;
  width: 100%;
  height: 83%;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  padding: $unit;
  border: 1px solid #88bfff;

  color: #88bfff;
  background-color: $white;

  &--unavailable {
    position: absolute;
    background: repeating-linear-gradient(45deg, #f7f7f7, #f7f7f7 10px, #fcfcfc 10px, #fcfcfc 20px);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &--pending {
    background: #c9e2ff;
    color: $white;
    border: none;
  }
}
