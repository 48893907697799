/*
    utils: width
*/

.w {
  &--50 {
    width: 50%;

    &--px {
      width: 50px;
    }

    &--max {
      max-width: 50px;
    }
  }

  &--80 {
    &--max {
      max-width: 80%;
    }
  }

  &--100 {
    width: 100%;

    &--px {
      width: 100px;
    }

    &--max {
      max-width: 100px;
    }
  }

  &--105 {
    width: 105px;
  }

  &--120 {
    width: 120px;

    &--max {
      max-width: 120px;
    }
  }

  &--128 {
    width: 128px;

    &--max {
      max-width: 128px;
    }
  }

  &--136 {
    width: 136px;

    &--max {
      max-width: 136px;
    }
  }

  &--156 {
    width: 156px;

    &--max {
      max-width: 156px;
    }
  }

  &--170 {
    width: 170px;
  }

  &--200 {
    &--max {
      max-width: 200px;
    }

    &--px {
      width: 200px;
    }
  }

  &--240 {
    width: 240px;

    &--max {
      max-width: 240px !important;
    }
  }

  &--260 {
    width: 260px;

    &--max {
      max-width: 260px !important;
    }
  }

  /* above 100 you don't need to include "px" suffix for pixels, because percentages are valid until 100 */
  &--300 {
    width: 300px;

    &--max {
      max-width: 300px;
    }
  }

  &--350 {
    width: 350px;

    &--max {
      max-width: 350px;
    }
  }

  &--400 {
    width: 400px;

    &--max {
      max-width: 400px !important;
    }
  }

  &--448 {
    width: 448px;

    &--max {
      max-width: 448px;
    }
  }

  &--550 {
    width: 550px;

    &--max {
      max-width: 550px !important;
    }
  }

  &--632 {
    width: 632px;

    &--max {
      max-width: 632px;
    }
  }

  &--680 {
    width: 680px;

    &--max {
      max-width: 680px;
    }
  }

  &--800 {
    &--max {
      max-width: 800px;
    }
  }

  &--900 {
    &--max {
      max-width: 900px;
    }
  }

  &--1200 {
    width: 1200px;
  }
}

.h {

  &--100{
    height: 100%;
  }

  &--40 {
    &--min {
      min-height: 40px;
    }
  }

  &--150 {
    &--min {
      min-height: 150px;
    }
  }

  &--175 {
    &--min {
      min-height: 175px;
    }
  }

  &--200 {
    &--min {
      min-height: 200px;
    }
  }
}
