@import 'src/styles/base/vars.scss';

.title {
    text-align: center;
    margin: 8px !important;
}

.inactive {
    border: 1px solid $grey-7f;
    color: $grey-7f;
}

.search {
    margin-top: 16px !important;
}

.options {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    margin-top: 16px;
    min-height: 150px;

    > *:last-child {
        margin-bottom: 12px;
    }
}

.option {
    width: 100%;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: none;
}

.option.selected {
    background-color: $color-primary-lighter;
}

.tabs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
}
