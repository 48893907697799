/*
    component: trial.scss;
*/

.trial {
  z-index: 11;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 80vw;
  height: 80vh;
  padding: calc(#{$unit} * 6);
  border-radius: $border-rounded;
  text-align: center;
  overflow-y: auto;

  background-color: $white;
  background-image: url('./../../assets/images/not-found.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  @media (max-width: $responsive-md) {
    width: 95vw;
  }

  &__overlay {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__btn {
    padding: calc(#{$unit} * 6) calc(#{$unit} * 28);

    div:first-child {
      font-size: 24px;
      @media (max-width: $responsive-md) {
        font-size: 18px;
      }
    }

    @media (max-width: $responsive-md) {
      padding: calc(#{$unit} * 6) calc(#{$unit} * 6);
    }
  }
}
