/*
    utils: align
*/

.align {
  &--center {
    margin: 0 auto;
  }

  &--middle {
    vertical-align: middle;
  }

  &--items {
    &--center {
      align-items: center;
    }
  }
}
